import { FC, useEffect, useState } from "react"
import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl"
import { Container } from "./styles"

const gameOverHandler = async () => {
	console.log("gameover handler")
	console.log("WORLD DESTROYED, redirect to result")
	window.location.href = "/games/result"
}

const gameCancelledHandler = async () => {
	console.log("GAME CANCELLED")
}

const gameErrorHandler = async (message: any) => {
	try {
		console.log({ gameErrorHandler: message })
		localStorage.setItem("game_error", message)
		console.log({ error: message, time: Date.now() + "" })
		// await sendEmergencyErrorFront({ error: message, time: Date.now() + "" })

		if (message.includes("NativeWebSocket.WebSocket.Close")) {
			const encodedError = encodeURIComponent("Very long connection. Try again")
			window.location.href = "/profile?infotext=" + encodedError
		}
	} catch (error) {
		console.log(error)
	} finally {
		// window.location.href="/games/error"
	}
}

const worldDestroyedHandler = async (withUpload?: boolean) => {
	console.log({ withUpload })
}

interface UnityAppProps {
	buildUrl: string
	setLoadingProgression: (x: number) => void
	joinRequestData?: string
	setGameIsLoad?: () => void
	showGame: boolean
}

const UnityApp: FC<UnityAppProps> = ({ buildUrl, joinRequestData, setGameIsLoad, setLoadingProgression, showGame }) => {
	const config: UnityConfig = {
		loaderUrl: buildUrl + "/Build.loader.js",
		dataUrl: buildUrl + "/Build.data.gz",
		frameworkUrl: buildUrl + "/Build.framework.js.gz",
		codeUrl: buildUrl + "/Build.wasm.gz",
		streamingAssetsUrl: buildUrl + "/StreamingAssets",
		companyName: "Qubix",
		productName: "Qubix",
		productVersion: "0.1",
		webglContextAttributes: {
			powerPreference: "high-performance",
		},
		cacheControl: () => "must-revalidate",
	}

	const [loadingProgress, setLoadingProgress] = useState(0)

	const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, unload } = useUnityContext(config)

	useEffect(() => {
		const formatedProgress = +loadingProgression.toFixed(2)
		if (formatedProgress !== loadingProgress) {
			setLoadingProgress(formatedProgress)
			setLoadingProgression(formatedProgress)
		}
	}, [loadingProgression])

	useEffect(() => {
		if (joinRequestData) {
			sendMessage("NetworkController", "SetJoinRequest", JSON.stringify(joinRequestData))
		}
	}, [joinRequestData])

	useEffect(() => {
		if (!isLoaded) return
		window.ReadyToStartFunction = async () => {
			console.log("GAME READY")
			if (setGameIsLoad) setGameIsLoad()
		}

		window.GameCancelledFunction = gameCancelledHandler
		window.GameIsOverFunction = gameOverHandler
		window.GameErrorFunction = gameErrorHandler
		window.WorldDestroyedFunction = worldDestroyedHandler

		return () => {
			window.ReadyToStartFunction = null
			window.GameCancelledFunction = null
			window.GameIsOverFunction = null
			window.GameErrorFunction = null
		}
	}, [unityProvider, isLoaded, joinRequestData])

	return (
		<Container id="unity-container" showGame={showGame}>
			<Unity className="unity" unityProvider={unityProvider} />
		</Container>
	)
}

export { UnityApp }
