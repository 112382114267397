import { GamesPlayed, NumberOfDeaths, NumberOfDefeats, NumberOfKills, NumberOfVictories, Winrate } from "Assets/Icons/Statistics"
import ContentBlockHOC from "Components/Molecules/Profile/ContentBlockHOC"
import { Loader, LoaderSpin } from "Pages/Games/Choose/styles"
import { useAppSelector } from "hooks/redux"
import { FunctionComponent, SVGProps, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useVerifySignatureMutation } from "services/AuthService"
import { useGetDailyGamesStatsMutation, useGetGamesStatsMutation } from "services/LedgerService"
import { useGetMeQuery } from "services/UserService"
import { ServerRegions, addNFT, gameSlice } from "store/reducers/GameSlice"
import { MetaData, getWNFTWalletInfo } from "store/reducers/WNFTSlice"
import { getWalletAccount, verifyMetamask } from "store/reducers/auth/MetamaskSlice"
import BugReport from "Components/Molecules/Profile/BugReport"
import HeroPanel from "Components/Molecules/Profile/HeroPanel"
import StartGamePanel from "Components/Molecules/Profile/StartGamePanel/Default"
import { savedNFTs } from "models/savedNFTs"
import { useLocation, useNavigate } from "react-router-dom"
import { getAccountNameFromSS, getStatusTransactionByHash } from "store/reducers/auth/NearSlice"
import { isAnonym, isArcanaUser, isMetaMaskUser, isNearUser } from "utils/checkUser"
import { getQueryParameterByName } from "utils/getQueryParameterByName"
import {
	AccountWrap,
	DailyMissions,
	DailyMissionsBlock,
	DailyMissionsDescription,
	DailyMissionsValue,
	LoaderWrap,
	ServerStatus,
	ServerStatusOnline,
	ServerStatusTitle,
	ServersPing,
	ServersPings,
	YourProgress,
	YourProgressItem,
	YourProgressName,
	YourProgressValue,
} from "./style"
import InfoModal from "Components/Molecules/Profile/Modals/InfoModal"
import NFTCardsComponent from "Components/Molecules/Profile/NFTCards"
import { Tooltip } from "antd"

export const FakeNFT = {
	metadata: {
		description: "Sniper. Extremely good at ranged combat, weak at melee.",
		external_url: "https://hub.qubixinfinity.io/",
		image: "https://game.qubixinfinity.io/meta/3.png",
		name: "Buller",
		attributes: [],
	},
	metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
	blockchain: "GOR",
	tokenId: "9999999",
	tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
	wNFTTokenId: "9999999",
	wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
	walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
	status: "wrapped",
	isDefaultNFT: true,
}

const AccountPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const search = useLocation().search
	const [infoText, setInfoText] = useState("")
	const [isShowInfoModal, showInfoModal] = useState(false)
	const [isTonPlayer, setIsTonPlayer] = useState(false)
	const [verifySignature, { data: signatureData, error: VerifyError, isSuccess: MetamaskSuccess, isLoading: MetamaskIsLoading }] =
		useVerifySignatureMutation()
	// const { user: arcanaUserData } = useAppSelector(state => state.arcana)
	const { isСhecked: tonIsChecked, isLoading: tonIsLoading, account: tonAccount, assets: tonAssets } = useAppSelector(state => state.ton)
	const { address, message, signature, chainId } = useAppSelector(state => state.metamaskReducer.account)
	const { account: NearAccount } = useAppSelector(state => state.nearReducer)
	const { pings: serversPings } = useAppSelector(state => state.game)
	const { data: meData, isSuccess: meIsSuccess } = useGetMeQuery()

	// useEffect(() => {
	// 	// if (!address) return
	// 	dispatch(getWalletAccount())
	// }, [])

	useEffect(() => {
		// если выбрана не та сеть и пользователь перешел на /profile
		console.log({ VerifyError })
		if (VerifyError && VerifyError["status"] === 400) {
			localStorage.clear()
			window.location.href = "/"
		}
	}, [VerifyError])

	// stats start
	const [getGamesStats, { data: userStats, isLoading: isLoadingUserStats }] = useGetGamesStatsMutation()
	const [getDailyGamesStats, { data: userDailyStats, isLoading: isLoadingUserDailyStats }] = useGetDailyGamesStatsMutation()
	const [statisticLines, setStatisticsLines] =
		useState<{ icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>; name: string; value: any }[]>()

	const dailyMissions = [
		{
			need: 3,
			description: `Play games`,
			value: userDailyStats?.game_played || 0,
		},
		{
			need: 1000,
			description: `Deal Damage`,
			value: userDailyStats?.damage || 0,
		},
		{
			need: 1,
			description: `Victory games`,
			value: userDailyStats?.victory || 0,
		},
		{
			need: 5,
			description: `Make kills`,
			value: userDailyStats?.kills || 0,
		},
	]

	useEffect(() => {
		const statLines = [
			{ icon: GamesPlayed, name: "Deaths", value: userStats?.deaths || 0 },
			{ icon: NumberOfDeaths, name: "Defeats", value: userStats?.defeat || 0 },
			{ icon: NumberOfDefeats, name: "Games", value: userStats?.game_played || 0 },
			{ icon: NumberOfKills, name: "Kills", value: userStats?.kills || 0 },
			{ icon: NumberOfVictories, name: "Victories", value: userStats?.victory || 0 },
			{ icon: NumberOfVictories, name: "Avarage lifetime, sec", value: userStats?.avglifetime || 0 },
			{ icon: NumberOfVictories, name: "Damage done", value: userStats?.damage || 0 },
			{ icon: Winrate, name: "Winrate, %", value: userStats?.winrate || 0 },
		]
		setStatisticsLines(statLines)
	}, [userStats])

	useEffect(() => {
		const checkAuth = async () => {
			if (isAnonym()) {
				sessionStorage.removeItem("access")
				sessionStorage.removeItem("refresh")
				return
			}

			if (isNearUser()) return

			if (isArcanaUser()) return

			if (!address || !chainId) {
				await dispatch(getWalletAccount())
			}

			if (!signature && address && chainId) {
				console.log("verifyMetamask from profile account")
				await dispatch(verifyMetamask())
			}

			if (signature) {
				await verifySignature({ address, message, signature, chainId })
			}
		}
		checkAuth()
	}, [dispatch, verifySignature, address, chainId, signature, message])

	useEffect(() => {
		const anonymousId = sessionStorage.getItem("anonymUserId")

		let userId: string

		if (signatureData && meData) {
			userId = meData.id
		} else if (tonAccount) {
			if (tonAccount?.decoded_jwt?.username) {
				userId = "ton_" + tonAccount?.decoded_jwt?.username?.replaceAll(".", "_dot_")
 			} else {
				userId = "ton_anonymous"
			}
		} else if (isNearUser() && NearAccount?.accountId) {
			userId = "near_" + NearAccount?.accountId.replaceAll(".", "_dot_")
		} else if (anonymousId) {
			userId = anonymousId
		} else {
			return console.error("Not fount user data for get stats")
		}

		getGamesStats({ userId })
		getDailyGamesStats({ userId })
	}, [getGamesStats, signatureData, NearAccount, tonAccount])

	// stats end

	// nft start

	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)

	useEffect(() => {
		if (!address) return
		dispatch(getWNFTWalletInfo())
	}, [address])

	/* 
		metadata: {
		description: string
		external_url: string
		image: string
		player_id?: string
		name: string
		attributes: { trait_type: string; value: number; max_value: number }[]
	}
	metadataURI?: string
	blockchain?: string
	tokenId?: string
	tokenAddress?: string
	wNFTTokenId?: string
	wNFTTokenAddress?: string
	walletAddress?: string
	status?: string
	*/

	const closeInfoModal = () => {
		// очистка текста из query после выхода из модалки
		showInfoModal(false)
		navigate("/profile")
	}

	const handleSetNFT = (nftName: string) => {
		localStorage.setItem("nftName", String(nftName))
		setNFT({ newSelectedNftName: nftName })
	}
	// nft end

	const setNFT = ({ newSelectedNftName, isPreselected }: { newSelectedNftName?: string; isPreselected?: boolean }) => {
		const getNftByNameFromSaved = (nftName: string) => {
			return savedNFTs.find(nft => nft.metadata.name.toLocaleLowerCase() === nftName.toLocaleLowerCase())
		}
		const nftName = newSelectedNftName || localStorage.getItem("nftName")
		const nearAccountNameFromSS = getAccountNameFromSS()
		const anonymPlayerId = sessionStorage.getItem("anonymUserId")

		let nft: MetaData
		let player_id = ""

		// существует ли такая nft в игре
		if (nftName && getNftByNameFromSaved(nftName)) {
			// если не прогружена инфа о тоне
			if (!tonIsChecked || (tonAccount && !tonAssets && isPreselected)) {
				nft = FakeNFT
			} else if (tonAccount && tonAssets) {
				const isExistNftInTonUserAssets = (n: string) => {
					return tonAssets?.content?.find((tonNFT: any) => tonNFT?.name?.toLocaleLowerCase() === n?.toLocaleLowerCase())
				}

				// принадлежит ли nft пользователю
				nft = isExistNftInTonUserAssets(nftName) ? getNftByNameFromSaved(nftName)! : FakeNFT
			} else {
				const findedNft = savedNFTs.find(nft => nft.metadata.name === nftName)
				if (findedNft) {
					nft = findedNft
				} else {
					nft = FakeNFT
				}
			}
		} else {
			// если явно выбрал Буллер то тоже сразу попадает сюда
			nft = FakeNFT
		}

		if (tonAccount) {
			if (tonAccount?.decoded_jwt?.username) {
				player_id = "ton_" + tonAccount?.decoded_jwt?.username.replaceAll(".", "_dot_")
 			} else {
				player_id = "ton_anonymous"
			}
		} else if (isMetaMaskUser() && meData?.id) {
			player_id = meData?.id
		} else if (nearAccountNameFromSS) {
			player_id = "near_" + nearAccountNameFromSS
		} else if (isAnonym() && anonymPlayerId && (tonIsChecked || isPreselected)) {
			if (tonIsChecked) {
				player_id = anonymPlayerId
			} else if (isPreselected) {
				// если еще нет никакой авторизации и эта nft ставится из sessionStorage
				player_id = anonymPlayerId
				nft = FakeNFT
				localStorage.setItem("nftName", FakeNFT.metadata.name)
			}
		} else {
			return console.log("Пользователь не опознан")
		}

		console.log({ player_id, nft })

		dispatch(addNFT({ ...nft, metadata: { ...nft.metadata, player_id: player_id } }))
	}

	const forceSelectMatchServer = (region: string) => {
		dispatch(gameSlice.actions.forceSelectMatchServer({ region }))
	}

	// set preselected nft
	useEffect(() => {
		setNFT({ isPreselected: true })
	}, [meData, NearAccount, tonAccount, tonIsChecked, tonAssets])

	useEffect(() => {
		// после оплаты ставки near идет редирект на /profile с хешем транзации в query параметрах
		// хеш оттуда забрается и сохраняется в redux
		if (!NearAccount) return

		const transactionHashes = getQueryParameterByName("transactionHashes")
		if (!transactionHashes) {
			console.log("No find transactionHashes")
			return
		}
		dispatch(getStatusTransactionByHash({ hash: transactionHashes }))
	}, [NearAccount])

	useEffect(() => {
		const infoText = getQueryParameterByName("infotext")
		if (infoText) {
			setInfoText(decodeURIComponent(infoText))
			showInfoModal(true)
		}
	}, [])

	return !isMetaMaskUser() || (MetamaskSuccess && meData && !isLoadingUserStats) ? (
		<AccountWrap>
			<HeroPanel />
			<StartGamePanel />
			<ContentBlockHOC title="daily missions" style={{ gridArea: "dayli_missions" }}>
				<DailyMissions>
					{dailyMissions.map((mission, index) => (
						<DailyMissionsBlock complete={mission.value! >= mission.need} key={index}>
							<DailyMissionsValue>
								{mission.value! >= mission.need ? (
									<>{mission.need}</>
								) : (
									<>
										{mission.value}
										<div>&nbsp;/&nbsp;</div>
										{mission.need}
									</>
								)}
							</DailyMissionsValue>
							<DailyMissionsDescription>
								{mission.value! >= mission.need ? mission.description + " done" : mission.description}
							</DailyMissionsDescription>
						</DailyMissionsBlock>
					))}
				</DailyMissions>
			</ContentBlockHOC>
			<ContentBlockHOC title="your progress" style={{ gridArea: "your_progress" }}>
				<YourProgress>
					{statisticLines &&
						statisticLines?.map((item, index) => (
							<YourProgressItem key={index}>
								<YourProgressName>{item.name}</YourProgressName>
								<YourProgressValue>{item.value}</YourProgressValue>
							</YourProgressItem>
						))}
				</YourProgress>
			</ContentBlockHOC>
			<ContentBlockHOC title="your nfts" style={{ gridArea: "your_nft" }}>
				<NFTCardsComponent handleSetNFT={handleSetNFT} />
			</ContentBlockHOC>
			<ServerStatus>
				<ServerStatusTitle>Server status</ServerStatusTitle>
				<ServerStatusOnline>online</ServerStatusOnline>
				<ServersPings>
					{serversPings?.regionPings &&
						Object.entries(serversPings?.regionPings).map(([region, server], index) => (
							<Tooltip title={server?.error} open={!!server?.error}>
								<ServersPing
									key={index}
									onClick={() => forceSelectMatchServer(region)}
									selected={serversPings.bestServerRegion?.server?.region === region}
								>
									<div>{ServerRegions[region]}:</div>
									<div>{server?.avg_ping || "-"} ms</div>
								</ServersPing>
							</Tooltip>
						))}
				</ServersPings>
				<BugReport />
			</ServerStatus>
			<InfoModal isModalOpen={isShowInfoModal} setIsModalOpen={closeInfoModal} text={infoText} />
		</AccountWrap>
	) : (
		<LoaderWrap>
			<Loader>
				<LoaderSpin />
			</Loader>
		</LoaderWrap>
	)
}

export default AccountPage
