import MatchmakingWinBet from "Components/Organisms/Matchmaking/MatchmakingWinBet"
import { useAppSelector } from "hooks/redux"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useGetLastGameStatsMutation } from "services/LedgerService"
import { useGetMeQuery } from "services/UserService"
import MatchmakingResultLogo from "../../../Components/Organisms/Matchmaking/MatchmakingResultLogo"
import MatchmakingStatistics from "../../../Components/Organisms/Matchmaking/MatchmakingStatistics"
import { Buttons, Container, MainMenuBtn, Wrapper } from "./styles"

function MatchmakingResult() {
	const navigator = useNavigate()
	const [getStats, { data, isLoading, isSuccess, isError, startedTimeStamp }] = useGetLastGameStatsMutation({
		// fixedCacheKey: "after_game",
	})
	const { account: tonAccount, isСhecked: tonIsChecked } = useAppSelector(state => state.ton)

	const mainMenuButtonHandler = () => {
		navigator("/profile")
	}

	useEffect(() => {
		function inIframe() {
			try {
				return window.self !== window.top
			} catch (e) {
				return true
			}
		}
		if (inIframe()) {
			if (!tonIsChecked) return
			if (tonIsChecked && tonAccount) {
				const player_id = "ton_" + tonAccount?.decoded_jwt?.username?.replaceAll(".", "_dot_")
				getStats({ userId: player_id })
				return
			}
			if (tonIsChecked && !tonAccount) {
				const anonymId = sessionStorage.getItem("anonymUserId")
				if (anonymId) {
					getStats({ userId: anonymId })
					return
				}
			}
		} else {
			const anonymId = sessionStorage.getItem("anonymUserId")
			if (anonymId) {
				getStats({ userId: anonymId })
				return
			}
		}

		navigator("/profile")
	}, [tonIsChecked, tonAccount])

	if (!startedTimeStamp || isLoading) {
		return <div>Loading</div>
	}

	if (startedTimeStamp && !data && isError) {
		navigator("/profile")
		return <></>
	}

	if (!data) {
		console.log("redirect3")
		navigator("/profile")
		return <></>
	}

	return (
		<Wrapper victory={data.winner || data.user_id === data.winnerid}>
			<Container>
				<MatchmakingResultLogo victory={data.winner || data.user_id === data.winnerid} />
				<MatchmakingStatistics
					stats={{
						kills: data.kills,
						damage: data.damage,
						deaths: data.deaths,
						avglifetime: data.avglifetime,
					}}
				/>
				<Buttons>
					<MainMenuBtn onClick={mainMenuButtonHandler}>main menu</MainMenuBtn>
				</Buttons>
			</Container>
		</Wrapper>
	)
}

export default MatchmakingResult
