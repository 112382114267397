import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useEffect, useState } from "react"
import { getIntervalMapThunk } from "store/reducers/IntervalMapSlice"
import { maps } from "../StartGamePanel/Default"
import { IntervalMapWrap, Map, Time, Title } from "./styles"

const TIME_INTERVAL = 300

function formatSeconds(seconds: number): string {
	const minutes = Math.floor(seconds / 60)
	const remainingSeconds = seconds % 60
	const formattedMinutes = String(minutes).padStart(2, "0")
	const formattedSeconds = String(remainingSeconds).padStart(2, "0")
	return `${formattedMinutes}:${formattedSeconds}`
}

const IntervalMap = () => {
	const dispatch = useAppDispatch()
	const [currentMapId, setMap] = useState<number>()
	const [countdownTimer, setCountdownTimer] = useState()
	const { currentMapId: gettedCurrentMapId, nextMapId, secondsToSwitch } = useAppSelector(state => state.intervalMapReducer)

	const setNextMap = () => {
		if (nextMapId) {
			setMap(nextMapId)
		}
	}

	useEffect(() => {
		if (gettedCurrentMapId) {
			setMap(gettedCurrentMapId)
		}
	}, [gettedCurrentMapId])

	useEffect(() => {
		dispatch(getIntervalMapThunk())
		const interval = setInterval(() => dispatch(getIntervalMapThunk()), 7.3 * 1000)

		return () => clearInterval(interval)
	}, [])

	return (
		<IntervalMapWrap>
			{(currentMapId && countdownTimer && !!maps.find(m => +m.map_id === currentMapId)) ? (
				<Map bg={maps.find(m => +m.map_id === currentMapId)?.bg || ""} active={false}>
					<Title>Map: {maps.find(m => +m.map_id === currentMapId)?.shortTitle}</Title>
					<Time>{formatSeconds(countdownTimer)}</Time>
				</Map>
			) : (
				<Map bg={maps[0].bg} active={false} fake={true}>
					<Title>Map: ...</Title>
					<Time>{formatSeconds(300)}</Time>
				</Map>
			)}

			{secondsToSwitch && (
				<CountdownTimerComponent
					seconds={Math.round(secondsToSwitch / 1000)}
					setNextMap={setNextMap}
					setCountdownTimer={setCountdownTimer}
				/>
			)}
		</IntervalMapWrap>
	)
}

let interval: ReturnType<typeof setInterval>

const CountdownTimerComponent: React.FC<{ seconds: number; setNextMap: () => void; setCountdownTimer: any }> = ({
	seconds,
	setNextMap,
	setCountdownTimer,
}) => {
	const [timeRemaining, setTimeRemaining] = useState(seconds)

	useEffect(() => {
		interval = setInterval(() => {
			setTimeRemaining(prevTime => {
				let result
				if (prevTime <= 1) {
					setNextMap()
					result = TIME_INTERVAL
				} else {
					result = prevTime - 1
				}
				setCountdownTimer(result)
				return result
			})
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (seconds !== undefined && Math.abs(timeRemaining - seconds) > 2) {
			for (let i = 0; i < 3; i++) {
				setTimeRemaining(seconds)
			}
		}
	}, [seconds])

	return <></>
}

export default IntervalMap
