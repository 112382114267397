import Image1234 from "../../../Assets/Icons/Hints/1234.png"
import JK from "../../../Assets/Icons/Hints/JK.png"
import Mouse from "../../../Assets/Icons/Hints/Mouse.png"
import or from "../../../Assets/Icons/Hints/or.png"
import Space from "../../../Assets/Icons/Hints/Space.png"
import wasd from "../../../Assets/Icons/Hints/WASD.png"
import { HintsWrap, Images, Movement, Attack, Abilities, Plus, Deskription, MovementItem, Title } from "./style"

const HintsForMainPage = () => {
	return (
		<HintsWrap>
			<Movement>
				<Images>
						<img src={wasd} alt="" />
					{/* <MovementItem>
					</MovementItem> */}
				</Images>
					<Deskription>Move</Deskription>
			</Movement>
			<Attack>
				<Images>
					<img src={JK} alt="" />
				</Images>
				<Deskription>Attack</Deskription>
			</Attack>
		</HintsWrap>
	)
}

export default HintsForMainPage
