import { ArrowLeftOutlined } from "@ant-design/icons"
import Hints from "Components/Atoms/Hints/indes"
import { Progress, notification } from "antd"
import axios from "axios"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import React, { FC, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { filesAPI } from "services/FilesService"
import ledgerAPI from "services/LedgerService"
import { useGetMeQuery } from "services/UserService"
import { ServerRegions, setMinPlayersForStart } from "store/reducers/GameSlice"
import { getWNFTWalletInfo } from "store/reducers/WNFTSlice"
import { generateGuestUserName, isAnonym, isArcanaUser, isNearUser } from "utils/checkUser"
import { isValidJR } from "utils/validationJR"
import {
	BackButton,
	LoaderSpin,
	LoadingWrap,
	ServerError,
	UnityContainer,
	WaitUsers,
	Wrapper,
	ProgressBarBlock,
} from "./styles"
import { UnityApp } from "../Unity/index"
import timeout from "utils/timeout"
import { WaveWordsPreloadWords, WaveWords } from "Components/Atoms/WaveWords"
import getGuestNickName from "utils/getGuestNickName"

interface InfinityLayoutProps {
	handleClickBack: any
	showGame: boolean
	children: React.ReactNode
}

const InfinityLayout: FC<InfinityLayoutProps> = ({ handleClickBack, showGame, children }) => {
	return (
		<Wrapper visible={true}>
			<BackButton onClick={handleClickBack}>
				<ArrowLeftOutlined style={{ marginRight: "10px" }} />
				Back
			</BackButton>
			<UnityContainer viewBackground={!showGame}>{children}</UnityContainer>
		</Wrapper>
	)
}

const messageEncoder = (message: unknown) => {
	return JSON.stringify(message)
}

const messageDecoder = (message: string) => {
	return JSON.parse(message)
}

let gameLoaded = false
const setGameIsLoad = () => {
	gameLoaded = true
}

enum AuthVariants {
	near = "near",
	anonym = "anonym",
	ton = "ton",
}

export interface RoomInfo {
	id: string
	map_id: string
	game_mode: string
	player_scheme: number[]
	lifetime: number
	start_wait: number
	players: unknown
	state: string
	created_at: number
	seed: number
	room_type: string
	team: boolean
	start_time?: null
	positions?: null
}

type userAuthVariantType = { [key in AuthVariants]: { jr_type: string; jr: any } }

function InfinityPage() {
	const location = useLocation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [timerStartGameForStatistics, setTimerStartGameForStatistics] = useState(Date.now())
	const [tokenPending, setTokenPending] = useState(false)
	const [webGlLoadProgress, setWebGlLoadProgress] = useState(0)
	const [roomInfo, setRoomInfo] = useState<RoomInfo>()
	const [showGame, setShowGame] = useState(false)
	const [joinRequestData, setJoinRequestData] = useState<any>()
	const unloadUnity = useRef<any>({})
	const [findRoomProcess, setFindRoomProcess] = useState(false)
	const interval = useRef<NodeJS.Timer | null>(null)
	const client = useRef<WebSocket | null>(null)
	const [
		getUrlWebGLBuild,
		{
			data: buildUrl,
			isLoading: isLoadingBuildUrl,
			isError: isErrorBuildUrl,
			isFetching: isFetchingBuildUrl,
			isSuccess: isSuccessBuildUrl,
			error: errorBuildUrl,
		},
	] = filesAPI.useLazyGetUrlWebGLBuildQuery()

	const { account: tonAccount } = useAppSelector(state => state.ton)
	const { preferences, isStartMatchmaking, isStartReconnecting, pings: serversPings } = useAppSelector(state => state.game)
	const [sendTimeWaitingMatch] = ledgerAPI.useSendTimeWaitingMatchMutation()

	const { address, message, signature, chainId } = useAppSelector(state => state.metamaskReducer.account)
	const { account: nearAccount, bet, rowHash } = useAppSelector(state => state.nearReducer)

	useEffect(() => {
		if (((isStartMatchmaking || isStartReconnecting) && isAnonym()) || isArcanaUser()) {
			loadGame()
		} else {
			// если пользователь попал на страницу игры, но до этого ничего не выбирал
			setTimeout(() => {
				if (!isStartMatchmaking) toProfile()
			}, 3000)
		}
	}, [isStartMatchmaking])

	const toProfile = () => {
		console.log("navigate to profile")
		navigate("/profile")
	}

	useEffect(() => {
		if (location?.state?.afterTransaction) {
			loadGame()
			// nearStart()
			return
		}
		if (!isAnonym()) {
			// dispatch(getWalletAccount())
		}
		return () => {
			closeSocket()
		}
	}, [])

	useEffect(() => {
		if (isAnonym()) return
		if (address && !signature) {
			dispatch(getWNFTWalletInfo())
		}
	}, [address, signature, dispatch])

	const handleTonFindRoom = () => {
		try {
			// установка minPlayersForGames из localStorage для near
			const minPlayersForGames = localStorage.getItem("minPlayersForGames")
			if (minPlayersForGames) {
				dispatch(setMinPlayersForStart(+minPlayersForGames))
			} else {
				localStorage.setItem("minPlayersForGames", "2")
				dispatch(setMinPlayersForStart(2))
			}
			console.log({ minPlayersForGames })

			const rawNickname = tonAccount?.decoded_jwt?.username || getGuestNickName()
			const nickname = rawNickname && (rawNickname?.length > 20) ? rawNickname.slice(0,20) : rawNickname
			
			const join_request = {
				preferences: {
					region: serversPings.bestServerRegion?.server.region || sessionStorage.getItem("region") || "EU",
					// ts-ignore
					game_mode: preferences.game_mode?.startsWith("flag") ? "flagCapture" : preferences.game_mode,
					map_id: 3,
					nfts_metadata: preferences.nft,
					minPlayersForStart: minPlayersForGames || 2,
					nickname
				},
			}

			return join_request
		} catch (e: unknown) {
			if (e instanceof Error) {
				throw new Error(`Find room: ${e.message}`)
			}
			return
		}
	}

	const handleNearFindRoom = () => {
		try {
			// установка minPlayersForGames из localStorage для near
			const minPlayersForGames = localStorage.getItem("minPlayersForGames")
			if (minPlayersForGames) {
				dispatch(setMinPlayersForStart(+minPlayersForGames))
			} else {
				localStorage.setItem("minPlayersForGames", "2")
				dispatch(setMinPlayersForStart(2))
			}
			console.log({ minPlayersForGames })

			const map_id = sessionStorage.getItem("map_id")
			const dapp = sessionStorage.getItem("dapp")
			const join_request = {
				preferences: {
					region: serversPings.bestServerRegion?.server.region || sessionStorage.getItem("region") || "EU",
					// ts-ignore
					game_mode: preferences.game_mode?.startsWith("flag") ? "flagCapture" : preferences.game_mode,
					map_id: map_id || 3,
					nfts_metadata: preferences.nft,
					bet,
					minPlayersForStart: minPlayersForGames || 2,
					dapp: dapp || "near",
				},
			}

			return join_request
		} catch (e: unknown) {
			if (e instanceof Error) {
				throw new Error(`Find room: ${e.message}`)
			}
			return
		}
	}

	const handleAnonymFindRoom = () => {
		try {
			const join_request = {
				preferences: {
					// region: "EU",
					region: serversPings.bestServerRegion?.server.region || "EU",
					// ts-ignore
					game_mode: preferences.game_mode?.startsWith("flag") ? "flagCapture" : preferences.game_mode,
					map_id: 6,
					nfts_metadata: preferences.nft,
					minPlayersForStart: preferences.minPlayersForStart,
					nickname: sessionStorage.getItem("anonymUserId") || generateGuestUserName()
				},
			}

			return join_request
		} catch (e: unknown) {
			if (e instanceof Error) {
				throw new Error(`Find room: ${e.message}`)
			}
			return
		}
	}

	const onStartGame = async () => {
		setTokenPending(false)
		setShowGame(true)

		// отправка времени проведенного в очереди
		sendTimeWaitingMatch({ time: Date.now() - timerStartGameForStatistics, server_url: process.env.REACT_APP_MATCHMAKING_SERVER || "null" })
	}

	const closeSocket = () => {
		client.current && client.current.close()
		interval.current && clearInterval(interval.current)
		setFindRoomProcess(false)
	}

	const messageProcessor = async (message: { type: string; message: string; data?: unknown }) => {
		switch (message.type) {
			case "error":
				const errorObj = message.message as any
				notification.info({
					message: errorObj?.message || errorObj,
					placement: "top",
				})
				setTimeout(() => {
					closeSocket()
					toProfile()
				})
				break
			case "jr":
				setJoinRequestData(message.data)
				localStorage.setItem("jr", JSON.stringify(message.data))
				await timeout(1000)
				onStartGame()
				closeSocket()
				break
			case "info":
				if (message.message === "RoomInfo") {
					console.log({ room_id_from_MM: message?.data })
					const room_info = message.data as RoomInfo

					setRoomInfo(room_info)
				}
				break
			case "pong":
				break
			default:
		}
	}

	const loadGame = async () => {
		await getUrlWebGLBuild({ mode: process.env.REACT_APP_URL_BUILD_MODE as any, env: (process.env.REACT_APP_URL_BUILD_ENV as any) || "dev" })
	}

	const startMatchmaking = async (near = false) => {
		try {
			console.log("startMatchmaking")
			setTimerStartGameForStatistics(Date.now())

			if (!preferences.game_mode || !preferences.nft) {
				notification.error({
					message: "Game data not found",
				})
				return
			}
			if (!process.env.REACT_APP_MATCHMAKING_FIND_ROOM) {
				notification.error({
					message: "Matchmaking not available",
				})
				return
			}

			// connect
			const wsc = new WebSocket(process.env.REACT_APP_MATCHMAKING_FIND_ROOM)
			wsc.onopen = async () => {
				await timeout(1000)
				client.current = wsc
				interval.current = setInterval(async () => {
					// сообщение нужно для поддржания актуальности юзера в очереди
					try {
						if (wsc.readyState === wsc.OPEN) {
							wsc.send(messageEncoder({ type: "ping" }))
						} else {
							handleError()
						}
					} catch (error) {
						handleError("Matchmaking error")
					}
				}, 1000)

				setFindRoomProcess(true)
				wsc.onmessage = event => {
					messageProcessor(messageDecoder(event.data))
				}

				const anonymJson = handleAnonymFindRoom()
				const nearJson = handleNearFindRoom()
				const tonJson = handleTonFindRoom()

				const userAuthVariant: userAuthVariantType = {
					near: { jr_type: "near_find_room", jr: nearJson },
					anonym: { jr_type: "anonym_find_room", jr: anonymJson },
					ton: { jr_type: "ton_find_room", jr: tonJson },
				}

				const sendFindRoom = async (userVariant: AuthVariants) => {
					try {
						if (!isValidJR(userAuthVariant[userVariant].jr)) {
							handleError("No user id found")
							notification.error({ message: "Data error" })
							return
						}
						const payload = { type: userAuthVariant[userVariant].jr_type, ...userAuthVariant[userVariant].jr }
						console.log({ payloadToMM: payload })
						wsc.send(messageEncoder(payload))
					} catch (error) {
						console.error(error)
						handleError("Find Room error")
					}
				}

				if (isNearUser()) {
					sendFindRoom(AuthVariants.near)
					return
				}
				if (tonAccount) {
					sendFindRoom(AuthVariants.ton)
					return
				}
				if (isAnonym()) {
					sendFindRoom(AuthVariants.anonym)
					return
				} else {
					sendFindRoom(AuthVariants.anonym)
					return
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	const handleError = (message?: string) => {
		if (message) {
			notification.info({
				message,
				placement: "top",
			})
		}
		closeSocket()
		toProfile()
	}

	const cancelFindRoom = async ({ onLoadError = false, infoText = "", redirect = false }) => {
		// если игра уже загружается или началась
		if (onLoadError) {
			if (infoText) {
				navigate(`/near/return_money?infotext=${infoText}`)
			} else {
				navigate("/near/return_money")
			}
			return
		}
		if (showGame || redirect) {
			await client.current?.send(messageEncoder({ type: "cancel" }))
			closeSocket()
			window.location.href = "/profile"

			return
		}
		if (isNearUser() || (nearAccount?.accountId && rowHash)) {
			navigate("/near/return_money")
			return
		}
		if (client.current) {
			await client.current?.send(messageEncoder({ type: "cancel" }))
		}
		try {
			if (unloadUnity.current) {
				await unloadUnity.current()
			}
		} catch (error) {}
		setTimeout(() => {
			closeSocket()
			navigate("/profile")
		}, 300)
	}

	const handleGameLoad = () => {
		if (!gameLoaded) {
			setGameIsLoad()
			startMatchmaking()
		}
	}

	const handleSetLoadProgression = (progress: number) => {
		setWebGlLoadProgress(progress)
	}

	// отработка загрузки url билда юнити
	if (isErrorBuildUrl) {
		setTimeout(() => {
			window.location.href = "/profile"
		}, 1000)
		return (
			<InfinityLayout handleClickBack={() => cancelFindRoom({})} showGame={showGame}>
				<ServerError>Server error</ServerError>
			</InfinityLayout>
		)
	}
	if (isFetchingBuildUrl || isLoadingBuildUrl || !buildUrl?.url) {
		return (
			<InfinityLayout handleClickBack={() => cancelFindRoom({})} showGame={showGame}>
				<WaitUsers>
					<WaitUsers>
						<WaveWords text="Connecting to server..." />
					</WaitUsers>
					<Hints />
				</WaitUsers>
			</InfinityLayout>
		)
	}

	return (
		<InfinityLayout
			handleClickBack={() => cancelFindRoom({ redirect: true })}
			showGame={showGame}
		>
			{!gameLoaded ? (
				<WaitUsers>
					<WaitUsers>
						<WaveWords text="Download game" />
					</WaitUsers>
					<ProgressBarBlock>
						<Progress percent={webGlLoadProgress ? webGlLoadProgress * 100 : 2} showInfo={false} />
					</ProgressBarBlock>
					<Hints />
				</WaitUsers>
			) : findRoomProcess && !showGame ? (
				<WaitUsers>
					<LoadingWrap>
						<LoaderSpin />
					</LoadingWrap>
					<WaveWordsPreloadWords />
					<WaveWords text={`Server: ${ServerRegions[serversPings.bestServerRegion?.server.region || sessionStorage.getItem("region") as string]}`} />
					<Hints />
				</WaitUsers>
			) : (
				!showGame && (
					<WaitUsers>
						<LoadingWrap>
							<LoaderSpin />
						</LoadingWrap>
						<WaitUsers>
							<WaveWords text="Game ready" />
						</WaitUsers>
						<WaitUsers>
							<WaveWords text="Connecting to server..." />
						</WaitUsers>
						<Hints />
					</WaitUsers>
				)
			)}
			<UnityApp
				buildUrl={buildUrl?.url}
				joinRequestData={joinRequestData}
				setGameIsLoad={handleGameLoad}
				setLoadingProgression={handleSetLoadProgression}
				showGame={showGame}
			/>
		</InfinityLayout>
	)
}

export default InfinityPage
