import "App.css"
import TonError from "Components/Organisms/Auth/TonError"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { getAllUserInfoTON, tonSlice } from "store/reducers/auth/TonSlice"
import "swiper/swiper-bundle.min.css"
import AppRouter from "./Routes"
import { notification } from "antd"

function App() {
	const dispatch = useAppDispatch()
	const { account: tonAccount, error: tonError } = useAppSelector(state => state.ton)

	//auth ton
	useEffect(() => {
		function inIframe() {
			try {
				return window.self !== window.top
			} catch (e) {
				return true
			}
		}

		const authTon = async () => {
			// const appWorkInIframe = inIframe()
			const tokenFromQuery = new URLSearchParams(window.location.search).get("token")
			const tokenFromSS = sessionStorage.getItem("ton_token")

			if (tokenFromQuery) {
				dispatch(getAllUserInfoTON({ token: tokenFromQuery }))
			} else if (tokenFromSS) {
				dispatch(getAllUserInfoTON({ token: tokenFromSS }))
			}
		}
		authTon()
	}, [])

	if (tonError) {
		return <TonError text={tonError} />
	}

	return (
		<Router>
			<AppRouter />
		</Router>
	)
}

export default App
