import { Button, Form, Input, Modal, notification } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useAppSelector } from "hooks/redux"
import { useEffect, useState } from "react"
import ledgerAPI from "services/LedgerService"
import { isAnonym } from "utils/checkUser"
import { BugReportButton, BugReportFieldTitle, BugReportModal, SendButton } from "./style"
import "./style.css"

const BugReport = () => {
	const [form] = Form.useForm()
	const [text, setText] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [sendBugReport, { isLoading, isError, isSuccess }] = ledgerAPI.useSendBugReportMutation()
	const { address } = useAppSelector(state => state.metamaskReducer.account)
	const { account } = useAppSelector(state => state.nearReducer)

	const handleOnSendReport = async () => {
		let userId

		if (isAnonym()) {
			userId = sessionStorage.getItem("anonymUserId")
		} else if (address) {
			userId = address
		} else if (account?.accountId) {
			userId = account?.accountId
		} else {
			userId = localStorage.getItem("nftID")
		}

		await sendBugReport({ text, userId: userId || "null" })
		setText("")
	}

	const handleOnChangeText = (e: any) => {
		// const text = form.getFieldValue("text")
		const value = e?.target?.value
		// form.setFieldValue("text", "")
		setText(value)
	}

	useEffect(() => {
		if (isError) {
			notification.error({ message: "An error has occurred. Report has not been sent" })
		}
		if (isSuccess) {
			notification.success({ message: "Report was sent successfully" })
		}
		handleOnClose()
	}, [isError, isSuccess])

	const handleOnClose = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<BugReportModal>
				<Modal
					title="Bug Report !"
					open={isModalOpen}
					onOk={handleOnSendReport}
					onCancel={handleOnClose}
					className="bugreport"
					centered={true}
					style={{ borderRadius: "10px" }}
					footer={[
						<Button type="primary" loading={isLoading} onClick={handleOnSendReport}>
							<SendButton>Send</SendButton>
						</Button>,
					]}
				>
					<BugReportFieldTitle>Text problem:</BugReportFieldTitle>
					<TextArea
						style={{ color: "black", fontSize: "14px" }}
						maxLength={250}
						value={text}
						autoFocus
						onChange={handleOnChangeText}
					/>
				</Modal>
			</BugReportModal>
			<BugReportButton onClick={() => setIsModalOpen(true)}>Bug Report !</BugReportButton>
		</>
	)
}

export default BugReport