import PlaceBet from "Components/Organisms/Bet/PlaceBet"
import { Navigate, Route, Routes } from "react-router-dom"
import NotFound from "../Pages/Auth/NotFound/NotFound"
import AuthRoutes from "./AuthRoutes"
import GamesRoutes from "./GamesRoutes"
import MatchmakingRoutes from "./MatchmakingRoutes"
import NearRoutes from "./NearRoutes"
import PrivateRoute from "./PrivateRoute"
import ProfileRoutes from "./ProfileRoutes"

const AppRouter = () => {
	return (
		<Routes>
			<Route path="/">
				<Route index element={<Navigate to="/profile" />} />
				<Route
					path="profile/*"
					element={
						<PrivateRoute>
							<ProfileRoutes />
						</PrivateRoute>
					}
				/>
				<Route path="auth/*" element={<AuthRoutes />} />
				<Route
					path="games/*"
					element={
						<PrivateRoute>
							<GamesRoutes />
						</PrivateRoute>
					}
				/>
				<Route
					path="matchmaking/*"
					element={
						<PrivateRoute>
							<MatchmakingRoutes />
						</PrivateRoute>
					}
				/>
				<Route path="not_found" element={<NotFound />} />
			</Route>
			<Route path="near/*" element={<NearRoutes />} />
			<Route path="place_a_bet" element={<PlaceBet />} />
			<Route path="*" element={<Navigate to="/not_found" />} />
		</Routes>
	)
}

export default AppRouter
