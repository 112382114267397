import { FakeNFT } from "Components/Organisms/Profile/Account"
import { useAppSelector } from "hooks/redux"
import { savedNFTs } from "models/savedNFTs"
import { FC } from "react"
import { DefaultNFTCard, NFTCard, NFTCards, NFTImage, NFTName } from "./style"
import { ExportIcon } from "Assets/Icons/Common"

const rarityColors = {
	common: "white",
	uncommon: "rgb(112,211,196)",
	epic: "rgb(220,108,242)",
	rare: "rgb(76,167,248)",
	legendary: "rgb(232,140,108)",
}

interface NFTCardsComponentProps {
	handleSetNFT: (nftName: string) => void
}

const NFTCardsComponent: FC<NFTCardsComponentProps> = ({ handleSetNFT }) => {
	const { isСhecked: tonIsChecked, isLoading: tonIsLoading, account: tonAccount, assets: tonAssets } = useAppSelector(state => state.ton)
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)

	return (
		<NFTCards>
			<DefaultNFTCard onClick={() => handleSetNFT(FakeNFT.metadata.name)} isActive={selectedNFT?.wNFTTokenId === FakeNFT.wNFTTokenId}>
				<NFTImage>
					<img src={FakeNFT.metadata.image} alt="" />
				</NFTImage>
				<NFTName>{FakeNFT.metadata.name}</NFTName>
			</DefaultNFTCard>
			{tonIsChecked &&
				tonAccount &&
				tonAssets?.content.map((nft, index) => (
					<NFTCard
						onClick={() => handleSetNFT(nft.name)}
						isActive={selectedNFT?.metadata.name?.toLocaleLowerCase() === String(nft.name).toLocaleLowerCase()}
						key={index}
					>
						<NFTImage>
							<img src={nft.image} alt="" />
						</NFTImage>
						<NFTName color={rarityColors[nft?.properties?.rarity?.toLocaleLowerCase()] || "white"}>{nft.name}</NFTName>
					</NFTCard>
				))}
		</NFTCards>
	)
}

export default NFTCardsComponent
