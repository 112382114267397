import styled from "styled-components"

export const Container = styled.div<{showGame: boolean}>`
	position: ${({showGame}) => showGame ? "relative" : "absolute"};

	display: grid;
	place-items: center;

	> canvas {
		background: ${({ theme }) => theme.color.background.auth};
		height: 85vh;
		aspect-ratio: 16 / 9;
	}

	& .unity {
		opacity: ${({showGame}) => showGame ? 1 : 0};
		z-index: 3;
	}
`
