import styled from "styled-components"
import { Colors, Content, Flex } from "../../../globalStyles"
// import background3 from "../../../Assets/Images/Background3.jpg"
import background3 from "../../../Assets/Images/Background3-Compressed.jpg"

// export const ProfileWrapper = styled.div`
export const ProfileWrapper = styled(Content)`
	position: relative;
	min-height: 100vh;
	min-width: 950px;

	background-color: #04001b;
	z-index: 1;
	box-sizing: border-box;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: url(${background3}) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		z-index: -1;
	}

	display: grid;
	grid-template-areas:
		"header"
		"content";
	/* grid-template-columns: 400px 1fr; */
	grid-template-rows: auto 1fr;
	transition: 0.3s all ease;
`

export const ProfileBody = styled.div`
	display: grid;
	place-content: center;
`

export const ProfileFooter = styled.div`
	grid-area: footer;
	height: 310px;
	margin-top: 100px;
	padding: 70px 100px;

	position: relative;
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	justify-items: center;
	grid-template-columns: auto 1fr auto;
	column-gap: 60px;

	border-top: 1px solid ${Colors.grey};

	& svg {
		width: 240px;
		height: 90px;
	}
`

export const Links = styled.div`
	width: 100%;
	display: grid;
	justify-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fill, minmax(120px, 40%));
	column-gap: 20px;

	& a {
		/* min-height: 40px; */
	}
`

export const Socials = styled.div``

export const SocialsTitle = styled.div``
export const SocialsList = styled.div`
	display: grid;
	width: min-content;
	grid-auto-flow: column;
	column-gap: 16px;
`

export const SocialItem = styled.div`
	padding: 7px;
	text-align: center;
	border-radius: 5px;
	background-color: white;
	cursor: pointer;
	width: 40px;
	height: 40px;
	& svg {
		width: 27px;
		height: 27px;
		z-index: 5;
	}
`

export const QubixAuthor = styled.div`
	position: absolute;
	bottom: 70px;
	right: 100px;

	color: #8d8d8f;
	font-size: 16px;
	font-weight: 500;
`
