import { notification } from "antd"
import axios from "axios"
import { useAppSelector } from "hooks/redux"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addMap, gameSlice, setMinPlayersForStart } from "store/reducers/GameSlice"
import getJRFromLS from "utils/getJRFromLS"
import { ReactComponent as TicketIcon } from "../../../../../Assets/Icons/Menu/buy_a_ticket.svg"
import ChooseRoomImage2 from "../../../../../Assets/Images/Matchmaking/maps/Biohive.jpg"
import ChooseRoomImage5 from "../../../../../Assets/Images/Matchmaking/maps/Colloseum.jpg"
import ChooseRoomImage3 from "../../../../../Assets/Images/Matchmaking/maps/Neon.jpg"
import ChooseRoomImage3_1 from "../../../../../Assets/Images/Matchmaking/maps/Neon_changed.jpeg"
import ChooseRoomImage4 from "../../../../../Assets/Images/Matchmaking/maps/UnchartedLand.jpg"

import NewChooseMinPlayersForStart from "Components/Atoms/Matchmaking/NewChooseMinPlayersForStart"
import { useInterval } from "react-use"
import { useGetDailyGamesStatsMutation } from "services/LedgerService"
import { authToNear, getAccountNameFromSS } from "store/reducers/auth/NearSlice"
import { average } from "utils"
import { isAnonym } from "utils/checkUser"
import { useWalletSelector } from "utils/near/wallet"
import ChooseMapModal from "../../Modals/ChooseMapModal"
import HighPing from "../../Modals/HighPing"
import ToManySessionsModal from "../../Modals/ToManyActiveSessions"
import { BuyTicketButton, BuyTicketButtonBody, Loading, ReadyBotton, Wrapper, WrapperAuth, WrapperLoading } from "./style"
import IntervalMap from "../../IntervalMap"

const MAX_GAMES_COUNT = 300

export const maps = [
	{ shortTitle: "NEAR", title: "NEAR Foundation", bg: ChooseRoomImage4, map_id: "3", forMM: "near" },
	{ shortTitle: "Arctic", title: "Arctic Wallet", bg: ChooseRoomImage2, map_id: "4", forMM: "arctic" },
	{ shortTitle: "Nightly", title: "Nightly", bg: ChooseRoomImage3, map_id: "5", forMM: "nightly" },
	{ shortTitle: "Octopus", title: "Octopus", bg: ChooseRoomImage5, map_id: "6", forMM: "octopus" },
	{ shortTitle: "QSTN", title: "QSTN", bg: ChooseRoomImage3_1, map_id: "13", forMM: "qstn" },
	// { shortTitle: "Usual", title: "Usual", bg: ChooseRoomImage3_1, map_id: "1", forMM: "near" },
]

export interface Server {
	id: string
	server_ip: string
	server_url: string
	server_port: string
	last_heartbeat: number
	region: string
}

const StartGamePanel = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [getGamesStats, { data: userStats, isLoading: isLoadingUserStats }] = useGetDailyGamesStatsMutation()

	const { preferences: gamePreferences, pings: serversPings, urlToBestMatchServer } = useAppSelector(state => state.game)
	// const { isLoading: isLoadingArcanaAuth } = useAppSelector(state => state.arcana)
	const { isLoading: isLoadingNear, bet, account: nearAccount } = useAppSelector(state => state.nearReducer)
	const { account: tonAccount, isСhecked: tonIsChecked, token: tonToken } = useAppSelector(state => state.ton)
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)
	const [isModalOpenMS, setIsModalOpenMS] = useState(false)
	const [isModalOpenHP, setIsModalOpenHP] = useState(false)
	const [isModalOpenCM, setIsModalOpenCM] = useState(false)
	const [isTestPingPassed, testPingPassed] = useState(false)
	// const [isModalOpenIN, setIsModalOpenIN] = useState(false)
	const [activeMap, setActiveMap] = useState<number>(0)
	const [playerGames, setPlayerGames] = useState<number>(0)
	const [playButtonIsDisabled, setPlayButtonDisabled] = useState(false)
	const { modal, accounts, selector, accountId } = useWalletSelector()

	const onLogin = async (e: any) => {
		modal.show()
	}

	const handleModalMS = (mode: boolean) => {
		setIsModalOpenMS(mode)
	}
	const handleModalHP = (mode: boolean) => {
		setIsModalOpenHP(mode)
	}
	const handleModalCM = (mode: boolean) => {
		setIsModalOpenCM(mode)
	}
	// const handleModalIN = (mode: boolean) => {
	// 	setIsModalOpenIN(mode)
	// }

	const handleChooseMap = (map_index: number) => {
		setActiveMap(map_index)
		dispatch(addMap({ map_id: maps[map_index].map_id, map_index }))
		sessionStorage.setItem("dapp", maps[map_index].forMM)
	}

	const handleStart = async ({ skipTestPing = false, isTonStart = false }: { skipTestPing?: boolean; isTonStart?: boolean }) => {
		try {
			console.log("handleStart CLICK")
			setPlayButtonDisabled(true)

			handleModalHP(false)
			handleModalMS(false)
			handleModalCM(false)

			// повторение условия disable
			if (isDisabled()) {
				// handleModalMS(true)
				return
			}

			// проверка на пинг
			if (!skipTestPing) {
				if (!serversPings?.bestServerRegion) {
					notification.error({ message: "Not found valid match server" })
					return
				}
				// handleModalHP(true)
			}

			// пользователь сначала был на dev.hub потом пошел на ton
			// нужно очистить LS и SS
			// if (nearAccount && isTonStart) {
			// 	await dispatch(nearSlice.actions.clearSSandLS())
			// }

			// если near то редирект на оплату ставки и оттуда уже в игру
			if (nearAccount) {
				handleModalCM(true)
				setPlayButtonDisabled(false)
				return
			}

			await dispatch(gameSlice.actions.startMatchmaking())

			navigate("/games/infinity")
			setPlayButtonDisabled(false)
		} catch (error) {
			console.log(error)
		}
	}

	const testPing = async (): Promise<{ server: Server; avg_ping?: number }[] | void> => {
		try {
			// получение списка серверов
			const { data: servers } = await axios.get<Server[]>(process.env.REACT_APP_MATCHMAKING_SERVER! + "/all_match_servers")
			if (!servers?.length) {
				// error - нет серверов
			}

			const countPingRequest = 3
			const results: { server: Server; avg_ping?: number }[] = []
			await Promise.all(
				servers.map(async server => {
					const times = []
					for (let i = 0; i < countPingRequest; i++) {
						const start = Date.now()
						try {
							let url_to_match = ""
							if (server.server_port) {
								url_to_match = `http://${server.server_ip}:${server.server_port}` || process.env.REACT_APP_MATCH_SERVER!
							} else {
								url_to_match = `https://${server.server_url}` || process.env.REACT_APP_MATCH_SERVER!
							}
							await axios.get(url_to_match + "/ping", { timeout: 500 })
							times.push(Date.now() - start)
						} catch (error) {
							console.error(error)
						}
					}

					// если ни один пинг не прошел или был больше timeout
					if (!times.length) {
						results.push({ server })
						return
					}

					const avg_ping: number = average(times)
					results.push({ server, avg_ping })
				})
			)

			dispatch(gameSlice.actions.setPingsInfo({ regionPings: results }))

			return results
		} catch (error) {
			console.error(error)
		} finally {
			testPingPassed(true)
		}
	}

	useEffect(() => {
		testPing()

		const minPlayersForGames = localStorage.getItem("minPlayersForGames")
		if (minPlayersForGames) {
			dispatch(setMinPlayersForStart(+minPlayersForGames))
		} else {
			const minPlayersForGames = localStorage.setItem("minPlayersForGames", "2")
			dispatch(setMinPlayersForStart(2))
		}

		const map_index = sessionStorage.getItem("map_index")
		if (map_index) {
			handleChooseMap(+map_index)
		} else {
			handleChooseMap(0)
		}

		const anonymPlayerId = sessionStorage.getItem("anonymUserId")
		if (anonymPlayerId) {
			getGamesStats({ userId: anonymPlayerId })
		}
	}, [])

	useInterval(testPing, 5000)

	const isDisabled = () => {
		if (!isTestPingPassed) return true
		if (playButtonIsDisabled) return true
		if (!serversPings.bestServerRegion) return true
		// if (isLoadingNear || !selectedNFT || !userStats || !tonIsChecked) return true
		// if (!nearAccount && !tonAccount) {
		// 	if (userStats?.game_played >= MAX_GAMES_COUNT) return true
		// }
		return false
	}

	const handleBuyTicket = () => {
		dispatch(authToNear({}))
		setIsModalOpenMS(false)
	}


	if (isLoadingNear) {
		return (
			<WrapperLoading>
				<Loading>Loading...</Loading>
			</WrapperLoading>
		)
	}

	// if (tonAccount && tonIsChecked) {
	// 	return (
	// 		<WrapperAuth>
	// 			<NewChooseMinPlayersForStart />
	// 			<ReadyBotton disable={isDisabled()} onClick={() => handleStart({ isTonStart: true })}>
	// 				play
	// 			</ReadyBotton>
	// 			<ChooseMapModal
	// 				isModalOpen={isModalOpenCM}
	// 				setIsModalOpen={handleModalCM}
	// 				maps={maps}
	// 				setActiveMap={handleChooseMap}
	// 				activeMap={activeMap}
	// 				onClickContinue={handleClickContinueChooseMap}
	// 			></ChooseMapModal>
	// 			<HighPing isModalOpen={isModalOpenHP} connectAction={() => handleStart({ skipTestPing: true })} setIsModalOpen={handleModalHP} />
	// 		</WrapperAuth>
	// 	)
	// }

	return (
		<Wrapper>
			<IntervalMap />
			<ReadyBotton disable={isDisabled()} onClick={() => handleStart({})}>
				Play
			</ReadyBotton>
			<ToManySessionsModal isModalOpen={isModalOpenMS} action={handleBuyTicket} setIsModalOpen={handleModalMS} />
			<HighPing isModalOpen={isModalOpenHP} connectAction={() => handleStart({ skipTestPing: true })} setIsModalOpen={handleModalHP} />
		</Wrapper>
	)
}

export default StartGamePanel
