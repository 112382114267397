import Popups from "Components/Organisms/Profile/Popups"
import ProfileHeader from "Components/Organisms/Profile/ProfileHeader"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"
import { getWalletAccount } from "store/reducers/auth/MetamaskSlice"
import {
	ProfileBody,
	ProfileWrapper
} from "./styles"

function ProfileLayout() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [popup, setPopup] = useState<null | string>(null)

	const links = {
		discord: "https://discord.gg/DhxxNTTy5B",
		twitter: "https://twitter.com/QubixInfinity",
		instagram: "https://www.instagram.com/qubix.infinity/",
		telegram: "https://t.me/qubixinfinity",
	}

	useEffect(() => {
		dispatch(getWalletAccount())
	})

	return (
		<ProfileWrapper>
			<ProfileHeader changePopup={(popup: string | null) => setPopup(popup)} />
			<ProfileBody>
					<Outlet />
			</ProfileBody>
		</ProfileWrapper>
	)
}

export default ProfileLayout
