import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"
import axios from "axios"

interface IState {
	currentMapId: number | null
	nextMapId: number | null
	secondsToSwitch: number | null
}

const initialState: IState = {
	currentMapId: null,
	nextMapId: null,
	secondsToSwitch: null,
}

export const getIntervalMapThunk = createAsyncThunk("getIntervalMapThunk", async (_, {}) => {
	try {
		return await axios.get<IState>(process.env.REACT_APP_MATCHMAKING_SERVER! + "/get_interval_map").then(res => {
			console.log(res.data)
			return res.data
		})
	} catch (error) {
		return console.log(error)
	}
})

export const IntervalMapSlice = createSlice({
	name: "interval_map",
	initialState,
	reducers: {
		setIntervalMap(state, action: PayloadAction<IState>) {
			state = action.payload
		},
	},
	extraReducers: {
		// [getIntervalMapThunk.pending.type]: () => {},
		[getIntervalMapThunk.fulfilled.type]: (state, action: PayloadAction<IState>) => {
            state.currentMapId = action.payload.currentMapId
            state.nextMapId = action.payload.nextMapId
            state.secondsToSwitch = action.payload.secondsToSwitch
		},
		// [getIntervalMapThunk.rejected.type]: () => {},
	},
})

export default IntervalMapSlice
