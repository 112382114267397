import styled from "styled-components"

export const Header = styled.div`
	text-align: center;
	& span {
		text-transform: uppercase;
		font-size: 40px;
		font-weight: 900;
	}
`

export const StatisticsBlock = styled.div`
	text-align: center;
	justify-self: center;

	margin-top: 0px;
	padding: 20px;

	width: min(60vw, 1000px);
	/* min-width: 40%; */

	backdrop-filter: blur(10px);
	border: 1px solid white;
`

export const Stats = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(340px, 1fr));
	justify-content: center;
	column-gap: 50px;
	row-gap: 10px;
	/* justify-items: left; */
`

export const Stat = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	& span:nth-child(1) {
		font-weight: 400;
		font-size: 23px;
		line-height: 40px;
	}
	& span:nth-child(2) {
		font-weight: 900;
		font-size: 27px;
		line-height: 40px;
	}
`
