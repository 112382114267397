import seedrandom from "seedrandom"

export default function () {
	// Задаем сид и создаем генератор случайных чисел с фиксированным сидом
	const rnd = seedrandom(new Date().getMinutes() + "")

	// Генерируем случайное число
	const min = 25
	const max = 45
	const randomNumber = Math.floor(rnd() * (max - min + 1)) + min

	// Выводим результат
	return randomNumber
}
