import Image1234 from "../../../Assets/Icons/Hints/1234.png"
import JK from "../../../Assets/Icons/Hints/JK.png"
import Mouse from "../../../Assets/Icons/Hints/Mouse.png"
import or from "../../../Assets/Icons/Hints/or.png"
import Space from "../../../Assets/Icons/Hints/Space.png"
import wasd from "../../../Assets/Icons/Hints/WASD.png"
import { HintsWrap, Images, Movement, Attack, Abilities, Plus, Deskription, MovementItem, Title } from "./style"

const Hints = () => {
	return (
		<HintsWrap>
			<Movement>
				<Images>
					<MovementItem>
						<img src={wasd} alt="" />
						<Deskription>(To move)</Deskription>
					</MovementItem>
					<Plus>+</Plus>
					<MovementItem>
						<img src={Space} alt="" />
						<Deskription>(Dash)</Deskription>
					</MovementItem>
				</Images>
				<Title>Movement</Title>
			</Movement>
			<Attack>
				<Images>
					{[JK, or, Mouse].map((image, index) => (
						<img src={image} key={index} alt="" />
					))}
				</Images>
				<Title>Attack</Title>
			</Attack>
		</HintsWrap>
	)
}

export default Hints
