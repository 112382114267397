import styled from "styled-components"

export const HintsWrap = styled.div`
	margin: 0 auto;

	height: 50px;
	width: 80%;
	display: grid;
	align-items: baseline;
	grid-auto-flow: column;
	grid-template-columns: 50% 50%;

	text-align: center;
	& img {
		height: 50px;
	}
`

export const Images = styled.div`
	display: grid;
	grid-auto-flow: column;
	place-items: center;
`

const Block = styled.div`
	position: relative;
	display: grid;
`

export const Movement = styled(Block)``
export const Attack = styled(Block)`
	& img {
		height: 25px;
	}
`
export const Abilities = styled(Block)``
export const Plus = styled.div`
	font-size: 50px;
	font-weight: 700;
`
export const Deskription = styled.div`
	margin-top: 5px;
	text-align: center;
	font-size: 14px;
`
export const MovementItem = styled.div`
	display: grid;
	place-items: center;
	height: 100%;
	grid-template-rows: 70% 1fr;

	img {
		align-self: end;
	}
	&:first-child img {
		display: inline-block;
		margin-bottom: -10px;
	}
`
export const Title = styled.div`
	font-size: 14px;
	font-weight: 600;
`
