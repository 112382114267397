import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { assetAPI } from "../services/AssetService"
import { authAPI } from "../services/AuthService"
import { coinAPI } from "../services/CoinService"
import { exchangeAPI } from "../services/ExchangeService"
import { filesAPI } from "../services/FilesService"
import { gamesAPI } from "../services/GamesService"
import { ledgerAPI } from "../services/LedgerService"
import { marketAPI } from "../services/MarketService"
import { matchmakingAPI } from "../services/MatchmakingService"
import { rentNFTAPI } from "../services/RentNFTService"
import { userAPI } from "../services/UserService"
import { walletAPI } from "../services/WalletService"
import gameReducer from "./reducers/GameSlice"
import IntervalMapSlice from "./reducers/IntervalMapSlice"
import MetamaskReducer from "./reducers/auth/MetamaskSlice"
import NearReducer from "./reducers/auth/NearSlice"
import tonReducer from "./reducers/auth/TonSlice"

const rootReducer = combineReducers({
	metamaskReducer: MetamaskReducer,
	nearReducer: NearReducer,
	intervalMapReducer: IntervalMapSlice.reducer,
	game: gameReducer,
	ton: tonReducer,
	[authAPI.reducerPath]: authAPI.reducer,
	[filesAPI.reducerPath]: filesAPI.reducer,
	[userAPI.reducerPath]: userAPI.reducer,
	[walletAPI.reducerPath]: walletAPI.reducer,
	[coinAPI.reducerPath]: coinAPI.reducer,
	[assetAPI.reducerPath]: assetAPI.reducer,
	[exchangeAPI.reducerPath]: exchangeAPI.reducer,
	[marketAPI.reducerPath]: marketAPI.reducer,
	[matchmakingAPI.reducerPath]: matchmakingAPI.reducer,
	[gamesAPI.reducerPath]: gamesAPI.reducer,
	[ledgerAPI.reducerPath]: ledgerAPI.reducer,
	[rentNFTAPI.reducerPath]: rentNFTAPI.reducer,
})

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: false,
			})
				.concat(authAPI.middleware)
				.concat(filesAPI.middleware)
				.concat(userAPI.middleware)
				.concat(walletAPI.middleware)
				.concat(coinAPI.middleware)
				.concat(assetAPI.middleware)
				.concat(exchangeAPI.middleware)
				.concat(marketAPI.middleware)
				.concat(matchmakingAPI.middleware)
				.concat(gamesAPI.middleware)
				.concat(ledgerAPI.middleware)
				.concat(rentNFTAPI.middleware),
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
