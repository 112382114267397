import "@near-wallet-selector/modal-ui/styles.css"
import { Web3ReactProvider } from "@web3-react/core"
import ErrorBoundary from "Components/Organisms/Common/ErrorBoundary"
import "antd/dist/antd.css"
import { Buffer } from "buffer"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { ThemeProvider } from "styled-components"
import { WalletSelectorContextProvider } from "utils/near/wallet"
import Web3 from "web3"
import App from "./App"
import { MetaMaskProvider } from "./hooks/metamask"
import "./index.css"
import { setupStore } from "./store/store"
import { theme } from "./theme"

global.Buffer = Buffer

const store = setupStore()

export const persistor = persistStore(store)

const getLibrary = (provider: any) => {
	return new Web3(provider)
}

// disable all alerts and confirms
if (window) {
	window.alert = () => false
	window.confirm = (message: string) => false
}

ReactDOM.render(
	<Web3ReactProvider getLibrary={getLibrary}>
		<MetaMaskProvider>
			<Provider store={store}>
				<WalletSelectorContextProvider>
					<ThemeProvider theme={theme}>
						<ErrorBoundary>
							<App />
						</ErrorBoundary>
					</ThemeProvider>
				</WalletSelectorContextProvider>
			</Provider>
		</MetaMaskProvider>
	</Web3ReactProvider>,
	document.getElementById("root")
)
