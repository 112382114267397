import { WalletOutlined } from "@ant-design/icons"
import { MiniArrow } from "Assets/Icons/Common"
import { ReactComponent as MenuLogo } from "Assets/Images/Qubix_Logo.svg"
import { ReactComponent as Beta } from "Assets/Images/beta.svg"
import { Dropdown } from "antd"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetMeQuery } from "services/UserService"
import { useGetWalletQuery } from "services/WalletService"
import { nearSlice } from "store/reducers/auth/NearSlice"
import { isAnonym } from "utils/checkUser"
import genRandomNumberBySeed from "utils/genRandomNumberBySeed"
import { useWalletSelector } from "utils/near/wallet"
import TemplateAvatarPNG from "../../../../Assets/Images/TemplateAvatarPNG.png"
import EarlyAccess1PNG from "../../../../Assets/Images/early_access/early_access_120x50_1a.png"
import {
	AnonymId,
	Avatar,
	Container,
	DropdownOverlay,
	LogOutButton,
	Logo,
	MainLogo,
	Navigate,
	PlayersCount,
	PlayersCountTitle,
	PlayersCountValue,
	UserPanel,
	Wallet,
	WalletAddress,
	Content,
} from "./styles"

interface IProps {
	changePopup: (popup: string | null) => void
}

function ProfileHeader({ changePopup }: IProps) {
	const dispatch = useAppDispatch()
	const [randomCountPlayres, setRandomCountPlayers] = useState(0)
	const { data: meData, isSuccess: meIsSuccess, isLoading: isLoadingMeData } = useGetMeQuery()
	const { data: walletData, isError, isSuccess: walletIsSuccess, refetch } = useGetWalletQuery()
	const { account: tonAccount } = useAppSelector(state => state.ton)
	const { address, message, signature, chainId } = useAppSelector(state => state.metamaskReducer.account)
	const {
		account: nearAccount,
		contract: nearContract,
		walletConnection: nearWalletConnection,
		isLoading: isLoadingNear,
	} = useAppSelector(state => state.nearReducer)
	const [qbxmState, setQbxm] = useState<{ url: string; balance: string; shortName: string }>()
	const { modal, accounts, selector, accountId } = useWalletSelector()

	const handleLogOut = async () => {
		try {
			localStorage.clear()
			sessionStorage.clear()

			await dispatch(nearSlice.actions.resetAuthData())
			const wallet = await selector.wallet()
			await wallet.signOut()

			// TODO: проверить необходимость перезагрузки
			window.location.reload()
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		setRandomCountPlayers(genRandomNumberBySeed())
		const interval = setInterval(() => {
			setRandomCountPlayers(genRandomNumberBySeed())
		}, 10 * 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<Container>
			<Content>
				<Logo href="/">
					<MenuLogo id="main_logo" />
					<img src={EarlyAccess1PNG} alt="" />
				</Logo>
				<UserPanel>
					<PlayersCount>
						<PlayersCountTitle>Players:</PlayersCountTitle>
						<PlayersCountValue>{randomCountPlayres || ""}</PlayersCountValue>
					</PlayersCount>
					{isLoadingMeData || isLoadingNear ? (
						<></>
					) : tonAccount ? (
						<Wallet>
							<div onClick={e => e.preventDefault()} style={{ display: "grid", gridAutoFlow: "column" }}>
								<WalletAddress>{tonAccount?.decoded_jwt?.username || "Anonymous"}</WalletAddress>
							</div>
						</Wallet>
					) : nearAccount ? (
						<>
							<Wallet>
								<Dropdown
									overlay={
										<DropdownOverlay>
											<LogOutButton onClick={handleLogOut}>Log out</LogOutButton>
										</DropdownOverlay>
									}
									placement="bottom"
								>
									<div onClick={e => e.preventDefault()} style={{ display: "grid", gridAutoFlow: "column" }}>
										<WalletOutlined style={{ marginRight: "10px", fontSize: "10px" }} />
										<WalletAddress title={nearAccount?.accountId}>
											{nearAccount?.accountId.length > 15 ? (
												<>{`...${nearAccount?.accountId?.slice(nearAccount?.accountId.length - 10)}`}</>
											) : (
												<>{nearAccount?.accountId}</>
											)}
										</WalletAddress>
										<MiniArrow />
									</div>
								</Dropdown>
							</Wallet>
						</>
					) : (
						<AnonymId>ID: {sessionStorage.getItem("anonymUserId")?.split("-")?.at(-1)}</AnonymId>
					)}
					<Avatar>
						<img src={TemplateAvatarPNG} alt="" />
					</Avatar>
				</UserPanel>
			</Content>
		</Container>
	)
}

export default ProfileHeader
