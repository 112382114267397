import styled from "styled-components"

export const NFTCards = styled.div`
	gap: 20px;
	padding: 2px 5px;

	max-width: 100%;
	& > div {
		width: min(12vw, 280px);
		margin-right: 10px;
		white-space: normal;
		text-align: center;

		display: inline-block;
		display: inline-grid;
		place-items: center;
		/* height: min(300px,30vh); */
	}

	scrollbar-width: thin; /* "auto" или "thin"  */
	scrollbar-color: grey transparent; /* плашка скролла и дорожка */

	&::-webkit-scrollbar {
		height: 7px; /* ширина scrollbar */
	}
	&::-webkit-scrollbar-track {
		background: transparent; /* цвет дорожки */
	}
	&::-webkit-scrollbar-thumb {
		background-color: blue; /* цвет плашки */
		border-radius: 20px; /* закругления плашки */
		border: 10px solid grey; //padding вокруг плашки
	}

	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
`

const NFTCardTemplate = styled.div`
	position: relative;
	backdrop-filter: blur(5px);
	padding: 20px;
	border-radius: 5px;
	cursor: pointer;

	@media (max-width: 1800px) {
		padding: 10px;
	}

	&:hover {
		outline: 1px solid blue;
	}

	& > p {
		font-size: 80px;
		position: absolute;
		top: 15px;
		left: 0;
		right: 5px;
		text-align: center;
		@media (min-width: 1800px) {
			top: 10px;
		}
	}
`

export const NFTCard = styled(NFTCardTemplate)<{ isActive?: boolean; pulse?: boolean }>`
	&::after {
		content: "ACTIVE";
		position: absolute;
		display: ${({ isActive }) => (isActive ? "block" : "none")};
		top: 5px;
		left: 10px;
		color: #68ff5b;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
	}

	background-color: black;
	border-radius: 4px;

	outline: ${({ isActive }) => (isActive ? "1px solid #272727" : "none")};
`

export const DefaultNFTCard = styled(NFTCard)`
	&::before {
		content: "DEFAULT";
		position: absolute;
		top: 5px;
		right: 5px;
		color: white;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 0px 3px;
		border-radius: 2px;
	}
`

export const NFTRentText = styled.div`
	font-size: 12px;
	font-weight: 400;
	/* white-space: normal; */
`

export const NFTImage = styled.div`
	text-align: center;
	& img {
		width: min(10vw, 180px);
		margin: 3px 0;
		@media (min-width: 1800px) {
			margin-top: -10px;
		}
	}
	& svg {
		width: 70%;
		/* height: 120px; */
		margin-top: 15px;
		@media (min-width: 1800px) {
			margin-top: 0;
		}
	}
`

export const NFTName = styled.div<{color?: string}>`
	font-size: 14px;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: ${({color}) => color ? color : "white"};
`

export const NFTAttributes = styled.div`
	font-size: 14px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	column-gap: 15px;
	row-gap: 6px;
	margin-top: 5px;
	div:nth-child(2) {
		background-color: #ee2a7b;
	}
	div:nth-child(4) {
		background-color: #009ca6;
	}
	div:nth-child(6) {
		background-color: #ff6b00;
	}
`

export const NFTAttribute = styled.div``

export const NFTAttributeType = styled.div`
	line-height: 15px;
	font-size: 12px;
	@media (max-width: 1800px) {
		font-size: 11px;
	}
`
export const NFTAttributeValue = styled.div<{ value: number }>`
	height: 14px;
	width: ${props => props.value * 10}%;

	padding: 0 3px;

	font-weight: 700;
	font-size: 14px;
	line-height: 14px;

	border-radius: 2px;
	background-color: red;

	text-shadow: -1px 0 rgba(0, 0, 0, 0.5), 0 1px rgba(0, 0, 0, 0.5), 1px 0 rgba(0, 0, 0, 0.5), 0 -1px rgba(0, 0, 0, 0.5);
	color: white;
	/* color: black; */
`
