import styled from "styled-components"
import { Flex } from "../../../globalStyles"
import { LoadingOutlined } from "@ant-design/icons"

export const Wrapper = styled.div<{ visible: boolean }>`
	position: relative;
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
	padding: 80px 50px 30px;
	transition: opacity 0.3s ease-in-out;
`

export const UnityContainer = styled.div<{ viewBackground?: boolean }>`
	height: 100%;
	width: 100%;
	display: grid;
	align-items: center;
	box-sizing: content-box;
	position: relative;
	/* background-color: blue; */
	/* width: 100%;
    height: 95%;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
    padding: 10px;
    transition: all 0.3s ease; */
	background: ${({ theme, viewBackground }) => (viewBackground ? theme.color.background.auth : "unset")};

	/* &::after {
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        background: linear-gradient(to right,
        rgba(0, 255, 255, 1) 0%,
        rgba(218, 46, 175, 1) 100%);
        content: '';
        z-index: -2;
        border-radius: 8px;
        transition: all 0.3s ease;
    } */
`

// export const FullscreenButton = styled(Button)`
//     width: fit-content;
//     height: 50px;
//     position: absolute;
//     right: 5px;
//     top: 5px;
//     text-transform: uppercase;
//     z-index: 2;
//     background-size: 300% 100%;
//     background-position: 100% 0;

//     :hover {
//         background-position: 0 100%;
//     }
// `

const Button = styled.div`
	position: absolute;
	padding: 20px 30px;
	text-transform: uppercase;
	/* background-color: rgb(46,24,71); */
	backdrop-filter: blur(4px);
	border-radius: 15px;
	top: 5px;
	cursor: pointer;
	transition: 1s all ease;
	&:hover {
		background-color: ${({ theme }) => theme.color.background.auth};
	}
	user-select: none;
`

export const BackButton = styled(Button)`
	padding: 20px 30px 20px 25px;
	left: 50px;
`

export const WaitUsers = styled.div`
	display: grid;
	place-items: center;
	gap: 20px;
	position: relative;
	/* height: 300px; */
	/* width: 100%; */
	span {
		font-weight: 700;
	}
`

export const LoadingWrap = styled.div`
	display: grid;
	place-items: center;
	height: 100%;
	width: 100%;
`

export const LoaderSpin = styled(LoadingOutlined)`
	/* height: 100%;
	width: 100%; */
	svg {
		font-size: 80px;
		fill: rgb(218, 46, 175);
	}
`

export const Ping = styled.div`
	position: absolute;
	top: 0;
	color: white;
	font-size: 30px;
`

export const ServerError = styled.div`
	text-align: center;
	font-size: 30px;
	font-weight: 500;
`

export const ProgressBarBlock = styled.div`
	width: 50vw;
	.ant-progress-inner, .ant-progress-bg {
		height: 15px !important;
	}
`