export const theme = {
	color: {
		background: {
			auth: "#040C1E",
			exchange: "#021136",
			games: {
				chooseItem: "linear-gradient(146.35deg, #0A1534 0, #0A1534 100%)",
			},
		},
		text: {
			gradient: "180deg, #DA2EAF, #02B7B7",
		},
	},
	maxWidthLobby: "1600px",
	minWidthLobby: "1200px"
}
