import HintsForMainPage from "Components/Atoms/HintsForMainPage/indes"
import { useAppSelector } from "hooks/redux"
import { ReactComponent as RentNFT } from "../../../../Assets/Images/RentNFT.svg"
import { Content, HeroImage, HeroName, HeroPanelWrap } from "./style"

const HeroPanel = () => {
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)

	return (
		<HeroPanelWrap>
			{
				selectedNFT ? (
					<Content>
						<HeroImage>
							<img src={selectedNFT?.metadata.image} alt="" />
						</HeroImage>
						<HeroName>{selectedNFT?.metadata.name}</HeroName>
						<HintsForMainPage />
					</Content>
				) : (
					<Content>
						<HeroImage>
							<RentNFT />
						</HeroImage>
						<HeroName>CHOOSE NFT</HeroName>
					</Content>
				)
				// null
			}
		</HeroPanelWrap>
	)
}

export default HeroPanel
