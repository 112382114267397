import styled from "styled-components"

export const IntervalMapWrap = styled.div`
	width: 100%;
	height: 100%;
`

interface MapTypes {
	bg: string
	active: boolean
	fake?: boolean
}
export const Map = styled.div<MapTypes>`
	position: relative;
	display: grid;
	align-items: center;
    height: 100%;
    width: 100%;

	transition: 0.3s all;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background: url(${props => props.bg}) no-repeat center;
		/* background-size: 100% 300%; */
		background-size: 101% auto;
		/* background-size: cover; */

		filter: ${({ fake }) => (fake ? "grayscale(100%)" : "none")};

		transition: 0.3s all;
		border-radius: 0px;
		opacity: ${({ active }) => (active ? 0.9 : 0.6)};
	}
    border-radius: 0;
	box-shadow: ${({ active }) => active && "0px 0px 10px 10px purple"};
`

export const Title = styled.div`
	position: absolute;
	z-index: 2;
	padding: 0 20px;
	font-weight: 900;
	font-size: min(20px, 1.3vw);
	letter-spacing: 2px;
	user-select: none;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`

export const Time = styled(Title)`
	position: absolute;
	right: 0;
`
