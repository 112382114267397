import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { TONAssetsDTO, TONTonAuthRes } from "../../../models/ton.dto"

export const getAllUserInfoTON = createAsyncThunk("getAllUserInfoTON", async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
	try {
		dispatch(tonSlice.actions.setTonToken(token))

		const userInfo = await axios.get<TONTonAuthRes>(`${process.env.REACT_APP_HUB_SERVER}/api/v1/ton/auth`, { params: { token } })
		console.log({ "userInfo.data": userInfo.data })
		const { active, valid } = userInfo?.data?.check_info
		if (!userInfo?.data?.decoded_jwt?.username) {
			return rejectWithValue("Anonymous TonPlay user")
		}
		const wallet = userInfo?.data?.decoded_jwt?.wallet
		if (!userInfo?.data?.decoded_jwt?.username) {
			return rejectWithValue("Not found username")
		}
		if (active && valid && wallet) {
			dispatch(getUserAssetsTON({ walletAddress: wallet }))
		}
		return userInfo.data
	} catch (error: any) {
		return rejectWithValue(error.message)
	}
})

export const getUserAssetsTON = createAsyncThunk(
	"getUserAssetsTON",
	async ({ walletAddress }: { walletAddress: string }, { rejectWithValue }) => {
		try {
			const userAssets = await axios.get<TONAssetsDTO>(`${process.env.REACT_APP_HUB_SERVER}/api/v1/ton/assets_user`, {
				params: { walletAddress },
			})
			return userAssets.data
		} catch (error: any) {
			console.error(error)
			return rejectWithValue(error.message)
		}
	}
)

interface IState {
	isСhecked: boolean
	isLoading: boolean
	error: string | null
	account: TONTonAuthRes | null
	assets: TONAssetsDTO | null
	token: string
}

const initialState: IState = {
	isСhecked: false,
	isLoading: false,
	error: null,
	account: null,
	assets: null,
	token: "",
}

export const tonSlice = createSlice({
	name: "tonSlice",
	initialState,
	reducers: {
		setTonError(state, action: PayloadAction<string>) {
			state.error = action.payload
		},
		notFoundToken(state) {
			state.isСhecked = true
		},
		setTonToken(state, action) {
			console.log("setTonToken")
			state.token = action.payload
			sessionStorage.setItem("ton_token", action.payload)
		},
		clearLSandSS() {
			const tonToken = sessionStorage.getItem("ton_token") || ""
			const nftName = sessionStorage.getItem("nftName") || ""

			localStorage.clear()
			sessionStorage.clear()

			sessionStorage.setItem("ton_token", tonToken)
			sessionStorage.setItem("nftName", nftName)
		},
	},
	extraReducers: {
		[getAllUserInfoTON.pending.type]: (state, action) => {
			state.isLoading = true
		},
		[getAllUserInfoTON.fulfilled.type]: (state, action: PayloadAction<TONTonAuthRes>) => {
			state.isСhecked = true
			state.isLoading = false
			state.account = action.payload
			if (!action?.payload?.check_info?.valid) {
				state.error = "TonPlay token not valid"
			}
		},
		[getAllUserInfoTON.rejected.type]: (state, action) => {
			state.isСhecked = true
			state.isLoading = false
			// state.error = action.payload
			console.error(JSON.stringify({ action }))
			console.error({ action })
		},
		[getUserAssetsTON.pending.type]: (state, action) => {
			state.isLoading = true
		},
		[getUserAssetsTON.fulfilled.type]: (state, action: PayloadAction<TONAssetsDTO>) => {
			state.isСhecked = true
			state.isLoading = false
			state.assets = action.payload
		},
		[getUserAssetsTON.rejected.type]: (state, action) => {
			state.isСhecked = true
			state.isLoading = false
			// state.error = action.payload
			console.error(JSON.stringify({ action }))
			console.error({ action })
		},
	},
})

export default tonSlice.reducer
