import { useEffect, useState } from "react"
import { GradientText } from "./style"

const WaveWords = ({ text }: { text: string }) => {
	return <GradientText>{text}</GradientText>
}

const WaveWordsPreloadWords = () => {
	const words = [
		"Preparing weapons...",
		"Building arena for battle...",
		"A little coffee break...",
		"Setting up the connection...",
		"Arranging boosters...",
	]
	const [currentWord, setWord] = useState(words[0])

	const setNewWord = () => {
		const newWord = words[Math.round(Math.random() * words.length)]
		if (newWord === currentWord) {
			setNewWord()
		} else {
			setWord(words[Math.floor(Math.random() * words.length)])
		}
	}

	useEffect(() => {
		setNewWord()
		const interval = setInterval(() => {
			setNewWord()
		}, 2000)

		return () => clearInterval(interval)
	}, [])

	return <GradientText>{currentWord}</GradientText>
}

export { WaveWords, WaveWordsPreloadWords }
