import styled from "styled-components"

const modes = {
	withBet: {
		// withReconnect: "grid-template-areas: 'mode bet' 'room minPlayerForStart' 'ready ready' 'reconnect new-game';",
		withoutReconnect: "grid-template-areas: 'mode bet' 'room minPlayerForStart' 'ready ready' 'buyticket buyticket';",
	},
	withoutBet: {
		// withReconnect: "grid-template-areas: 'mode mode' 'room minPlayerForStart' 'ready ready' 'reconnect new-game';",
		withoutReconnect: "grid-template-areas: 'mode mode' 'room minPlayerForStart' 'ready ready' 'buyticket buyticket';",
	},
}
/* ${({ withBet, withReconnect }) => modes[withBet ? "withBet" : "withoutBet"][withReconnect ? "withReconnect" : "withoutReconnect"]} */

type WrapperTypes = {
	withBet: boolean
	withReconnect: boolean
}

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	grid-auto-flow: row;
	grid-template-rows: 1fr 3fr;

	row-gap: 10px;
	
	border-radius: 5px;
	background-color: rgba(22, 96, 183, 0.2);

	padding: 15px 25px;

	backdrop-filter: blur(4px);
	grid-area: start_game_panel;
`

export const WrapperAuth = styled(Wrapper)`
	/* grid-template-areas: "room minPlayerForStart" "ready ready"; */
	grid-template-areas: "room minPlayerForStart" "ready ready";
	grid-template-areas: "newMinPlayerForStart newMinPlayerForStart" "ready ready";
	grid-template-rows: 40% 55%;
`

export const WrapperLoading = styled.div`
	display: grid;
	place-items: center;
	background-color: rgba(22, 96, 183, 0.2);
	/* padding: 15px 25px; */

	grid-area: start_game_panel;
`

export const Loading = styled.div`
	display: grid;
	place-items: center;
	height: 100%;
	width: 100%;
	background-color: grey;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 600;
	opacity: 0.3;
`

export const Choose = styled.div``

export const ChooseMode = styled(Choose)`
	cursor: not-allowed;
	grid-area: mode;
`

export const ChooseRoom = styled(Choose)`
	cursor: pointer;
	grid-area: room;
`

export const ChooseTitle = styled.div`
	font-weight: 900;
	font-size: 16px;
	user-select: none;
	@media (min-width: 1800px) {
		font-size: 18px;
	}
`

export const ChooseSelector = styled.div`
	display: grid;
	align-items: center;
	grid-auto-flow: column;
	grid-template-columns: repeat(auto-fit, minmax(25px, min-content));
	height: 35px;
	padding: 0 10px;
	/* opacity: 0.4; */
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.35);
`

export const ChooseSelectorWithImage = styled(ChooseSelector)<{ urlImage: string }>`
	position: relative;
	background-color: rgba(0, 0, 0, 0);
	overflow: hidden;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: -5px;
		background: url(${props => props.urlImage}) no-repeat;
		background-size: 100% 200%;
		width: 105%;
		height: 100%;
		opacity: 1;
		z-index: -1;
	}
`

export const ColorIcon = styled.div<{ color: string }>`
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background-color: ${props => props.color};
`

export const ChooseSelectorTitle = styled.div`
	font-weight: 400;
	font-size: 18px;
	justify-self: left;
	user-select: none;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`

export const ChooseSelectorArrow = styled.div``

export const ActionButtons = styled.div`
	display: grid;
	grid-template-columns: calc(60% - 20px) 40%;
	gap: 20px;
`

export const ReadyBotton = styled.div<{ disable: boolean; fz?: string }>`
	display: grid;
	place-items: center;

	height: 100%;
	width: 100%;

	font-weight: 900;
	letter-spacing: 1px;
	font-size: 60px;

	text-align: center;
	user-select: none;

	border-radius: 15px;
	background: linear-gradient(180deg, #ff6b00 0%, #ff9548 100%);

	background: ${({ disable }) => (disable ? "grey" : "#ff6b00")};
	cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};

	transition: all 0.3s ease;
	text-transform: uppercase;
`

export const BuyTicketButton = styled(ReadyBotton)`
	grid-area: buyticket;
	font-weight: 500;

	height: min-content;
	width: 80%;
	padding: 12px;
	justify-self: center;

	line-height: 12px;
	background: none;
	background-color: black;
	border: 4px solid rgb(105, 42, 121);
`

export const BuyTicketButtonBody = styled.div`
	display: grid;
	place-items: center;
	grid-auto-flow: column;
	column-gap: 20px;
	span {
		font-size: 18px;
		font-weight: 900;
	}
`

export const ReconnectButton = styled(ReadyBotton)`
	grid-area: reconnect;
	font-size: clamp(13px, 0.9vw, 20px);
	letter-spacing: 2px;
	background-color: ${({ disable }) => (disable ? "grey" : "purple")};
`

export const NoReconnectButton = styled(ReadyBotton)`
	grid-area: new-game;
	font-size: clamp(13px, 0.9vw, 20px);
	letter-spacing: 2px;
`

// bet

export const ChooseBet = styled(Choose)`
	grid-area: bet;
	position: relative;
`
export const BodyBet = styled.div`
	display: grid;
	grid-template-columns: 1fr 40px;
	& input {
		padding: 0;
		height: 40px;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 1px;
		color: white;
		border-bottom: 1px solid white;
	}
	& input:hover,
	& input:focus {
		border-bottom: 1px solid white;
	}
	& .near_svg {
		height: 100%;
		width: 100%;
		display: grid;
		place-items: center;
	}
	& svg {
		text-align: center;
	}
`

export const StaticBet = styled.div`
	line-height: 35px;
	border-bottom: 1px solid white;
	user-select: none;
`
