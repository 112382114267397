import styled from "styled-components"
// import background from "Assets/Images/MatchmakingBackground.png"
import background from "Assets/Images/MatchmakingBackground-Compressed.png"
import { Flex } from "../../../globalStyles"

export const Wrapper = styled.div<{ victory?: boolean }>`
	width: 100%;
	height: 100vh;

	background: radial-gradient(ellipse at center, rgba(0,0,0,0) 20%, rgba(0,0,0,0.8) 100%);
	background-color: ${({ victory }) => (victory ? "#080039" : "#450000")};
	position: relative;

	display: grid;
	place-items: center;

	z-index: 1;
`

export const Container = styled.div`
	width: 100%;
	height: max-content;

	display: grid;
	place-items: center;
`

export const MatchmakingResultHeader = styled(Flex)`
	align-items: flex-start;
	justify-content: center;

	> #qubix_logo {
		width: 20%;
	}
`

export const Buttons = styled.div`
	position: absolute;
	width: 100%;
	bottom: 40px;
	display: grid;
	place-items: center;
`

const Btn = styled.p`
	width: max-content;
	height: max-content;
	padding: 0 15px;

	background-color: #ff6b00;
	text-transform: uppercase;
	font-weight: 900;

	border-radius: 5px;
	cursor: pointer;
`

export const MainMenuBtn = styled(Btn)`
	font-size: 46px;
`