import styled from "styled-components"

export const GradientText = styled.div`
	font-size: 24px;
	font-weight: 500;
	color: transparent;
	background-image: linear-gradient(20deg, white, grey, white);
	-webkit-background-clip: text;
	background-clip: text;
	background-size: 300px 300px;
	background-position: 0 0;
	animation: bp 2s infinite linear;

	@keyframes bp {
		from {
			background-position-x: 0px;
		}
		to {
			background-position-x: 300px;
		}
	}
`
